<template>
  <v-container fluid class="down-top-padding">
    <base-breadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></base-breadcrumb>
    <base-card heading="Avaliações" :button="button">
      <datatable-list
        :text="text"
        v-if="examFecth"
        :headers="headers"
        :sortBy="sortBy"
        :items="items"
        :emit="true"
        :showSelect="showSelect"
        :disabledDescription="disabledDescription"
        @select-item="openExamPreview($event)"
      ></datatable-list>
      <v-skeleton-loader
        v-else
        v-bind="{
        class: 'mb-1',
        boilerplate: true,
        elevation: 2
        }"
        v-for="i in 3"
        :key="i"
        type="table-heading"
      ></v-skeleton-loader>
          <Pagination
            @next="changePagination(1)"
            @prev="changePagination(-1)"
            :disable-prev="!pagination.prev_page_url"
            :disable-next="!pagination.next_page_url"
            class="mb-6"
          />

      <dialog-preview-exam @update="getExams()"/>
    </base-card>
  </v-container>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
export default {
  data: () => ({
    enunciated: 'teste',
    examPreview: false,
    examFecth: false,
    page: {
      title: 'Avaliação'
    },
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      },
      {
        text: 'Banco de Avaliações',
        disabled: false,
        to: '/examdatabase/list'
      }
    ],
    button: {
      show: false,
      url: '/examdatabase/add',
      text: 'Avaliação',
      icon: 'mdi-plus'
    },
    text: {
      searchLabel: 'Pesquisar Avaliações',
      emptyLabel: 'Nenhuma avaliação encontrada',
      addLabel: 'Adicionar Avaliação',
      addUrl: '/examdatabase/add',
      importLabel: 'Importar Avaliação',
      importUrl: '/examdatabase/add'
    },
    disabledDescription: 'Avaliação já aplicada.',
    headers: [
      {
        text: 'Avaliação',
        align: 'start',
        value: 'title'
      },
      { text: 'Descrição', value: 'subtitle', align: ' d-none' },
      { text: 'Questões', value: 'questions_count', align: 'center' },
      { text: 'Duração sugerida (Minutos)', value: 'duration', align: 'end' }
    ],
    sortBy: 'className',
    showSelect: true,
    items: [],
    pagination: {
      current_page: 1,
      per_page: 10,
      prev_page_url: false,
      next_page_url: false
    }
  }),
  methods: {
    ...mapActions('exams', ['getExam']),
    changePagination (page) {
      this.pagination.current_page += page
      this.getExams()
    },
    formatExams (questions) {
      const arr = []
      if (!questions.length) return arr
      questions.forEach((value, index) => {
        const obj = {
          ...value.data,
          checked: false,
          title: value.data.name,
          duration: value.data.duration,
          // subtitle: value.data.instructions.replace(/<[^>]*>?/gm, '').replace('\\(', '').replace('\\)', '').replace(/&nbsp;/g, ''),
          id: value.metadata.id,
          permissions: { ...value.permissions },
          pagination: {
            current_page: 1,
            per_page: 10,
            prev_page_url: false,
            next_page_url: false
          }
        }
        arr.push(obj)
      })
      return arr
    },
    openExamPreview (item) {
      this.getExam({ id: item.id })
    },
    async getExams () {
      const self = this
      try {
        this.examFecth = false
        const pagination = `?page=${this.pagination.current_page}&per_page=${this.pagination.per_page}`

        await this.$axios.get(`/organizations/${this.id}/exams-database` + pagination).then(response => {
          // const formated = this.formatQuestions(response.data.items)
          // this.items = this.checkSelected(formated)
          this.pagination = response.data.pagination
          this.items = this.formatExams(response.data.items)
          this.button.show = response.data.permissions.create
        })
      } catch (error) {
        const errosArray = Object.assign({}, error.response.data.errors)
        if (error.response.data.errors) {
          Object.keys(errosArray).forEach(function (item) {
            self.$store.dispatch('alert', {
              color: 'red',
              msg: errosArray[item][0]
            })
          })
        }
      } finally {
        this.examFecth = true
      }
      // get adms
    }
  },
  mounted () {
    this.getExams()
  }
}
</script>
